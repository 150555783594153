import React, { useEffect, useState } from "react";
// Chakra imports
import { Box, Text, VStack, Flex, useColorModeValue, Button, Spinner, HStack } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
// Translation
import { useTranslation } from 'react-i18next';
// Axios for HTTP requests
import axios from 'axios';
// React Router
import { useNavigate, useSearchParams } from 'react-router-dom';
// React Toastify for notifications
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Custom components
import Card from "components/card/Card";

export default function Settings() {

  // Chakra Color Mode Variables
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [courses, setCourses] = useState([]);
  const [coursesOption, setCoursesOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [soilderStatus, setSoilderStatus] = useState("affiliate");
  const navigate = useNavigate();

  // Fetch courses when language or user changes
  useEffect(() => {
    if (!searchParams.get("soldier")) return navigate('/admin/soldiers');

    const fetchUserData = async () => {
      try {
        // Fetch the selected user's data
        const userData = await axios.get(`/api/user/${searchParams.get("soldier")}`);
        const userCategories = userData.data.course_categories.map(cat => cat.course_category_number);

        // Fetch all courses and filter based on the user's categories
        const allCourses = await axios.get(`/api/courses`);

        // Filter the courses based on the categories of the selected user
        const legitCourses = allCourses.data.filter(course => {
          // Check if the user has this course already
          const isAlreadyInCourse = userData.data.courses.some(userCourse => userCourse.course_id === course.course_id);
          
          // Only include the course if the user is not already in it and if the course has a matching category
          return !isAlreadyInCourse && course.course_categories.some(cat => userCategories.includes(cat.course_category_number));
      });      

        // Update the course options for the select dropdown
        setCoursesOptions(legitCourses.map(course => ({
          label: course.course_name,
          value: course.course_id
        })));

        setLoadingPage(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchUserData();
  }, [i18n.language, searchParams]);

  // Validate form inputs
  const validateForm = () => {
    if (courses.length === 0) return toast.error(i18n.language === 'ar' ? 'الرجاء اختيار على الاقل دورة واحدة.' : 'Please select at least one course to assign.');
    return true;
  };

  // Handle errors
  const handleError = (error) => {
    const errorMessage = error.response?.data?.error || error.message;
    console.error("Error creating course:", errorMessage);
    toast.error(errorMessage);
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) return; // Ensure the form is valid before proceeding

    setLoading(true);

    try {
      // Prepare the data to send to the backend
      const requestBody = {
        userIds: [searchParams.get("soldier")], // 'courses' contains the soldier numbers of the selected users
        soilder_status: soilderStatus, // Selected soldier status
        soilder_assign_date: new Date().toISOString() // Current date as the assignment date
      };

      // Loop through each selected course and assign the user
      for (let courseId of courses) {
        // Send POST request to the endpoint to assign users to the course
        await axios.post(`/api/user/assign/course/${courseId}`, requestBody);

        // On success, show a success message
        toast.success(`User assigned to course ${courseId} successfully.`); // Show success message for each course
      }

      // After all requests have completed, clear the selected courses and redirect
      setCourses([]); // Clear selected courses
      navigate(`/admin/soldiers/view?soldier=${searchParams.get("soldier")}`); // Redirect to the course view page, passing all selected course IDs

    } catch (error) {
      console.log(error);
      handleError(error); // Handle any errors
    } finally {
      setLoading(false); // End the loading state
    }
  };

  if (loadingPage) return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl" // You can change this to "lg" or "2xl" if you need it even bigger
      />
    </Flex>
  )

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer rtl={i18n.language === "ar"} /> {/* Add the ToastContainer to render the toasts */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <VStack
          spacing={4}
          align="start"
          w={{ base: "75%", md: "500px" }}
          mx="auto"
          my={4}
        >
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("course_name")}
            </Text>
            <Select
              onChange={(selectedOptions) => {
                const selectedCourses = selectedOptions.map(option => option.value);
                setCourses(selectedCourses); // Set selectedCourses as an array of course values
              }}
              isMulti
              variant='filled'
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("please_select")}
              menuPortalTarget={document.body}
              options={coursesOption.map(option => ({
                label: option.label,
                value: option.value,
              }))}
            />
          </Box>
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("soilder_status")}
            </Text>
            <HStack spacing={0} w="100%">
              <Button
                colorScheme={soilderStatus === "affiliate" ? "green" : "gray"}
                variant={soilderStatus === "affiliate" ? "solid" : "outline"}
                w="33.3%"
                borderRightRadius={0}
                onClick={() => setSoilderStatus("affiliate")}
              >
                {t("soilder_status_affiliate")}
              </Button>
              <Button
                colorScheme={soilderStatus === "candidate" ? "yellow" : "gray"}
                variant={soilderStatus === "candidate" ? "solid" : "outline"}
                w="33.33%"
                borderRadius={0}
                onClick={() => setSoilderStatus("candidate")}
              >
                {t("soilder_status_candidate")}
              </Button>
              <Button
                colorScheme={soilderStatus === "withdrawn" ? "red" : "gray"}
                variant={soilderStatus === "withdrawn" ? "solid" : "outline"}
                w="33.3%"
                borderLeftRadius={0}
                onClick={() => setSoilderStatus("withdrawn")}
              >
                {t("soilder_status_withdrawn")}
              </Button>
            </HStack>
          </Box>
          <Box w="100%">
            <Button
              align='center'
              justifyContent='center'
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w='100%'
              h='44px'
              lineHeight='100%'
              borderRadius='5px'
              onClick={handleSubmit}
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                />
              ) : (
                <Text fontSize="sm" color={inputText}>
                  {t("assign_soldier")}
                </Text>
              )}
            </Button>
          </Box>
        </VStack>
      </Card>
    </Box>
  );
}